import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons'
import AuthUser from '../AuthUser';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import InputGroup from 'react-bootstrap/InputGroup';

export default function List() {
    const {user, identidad, setBase , base, setIdentidad, cargarDataGoToMain} = AuthUser();
    const navigate = useNavigate();

    const [empresa, setEmpresa] = useState(base);

    useEffect(()=>{
        fetchAlquileres() 
    },[])

    const fetchAlquileres = async () => {        
    }    

    const changeEmpresa = async (e) => {        
        e.preventDefault();
        setBase(empresa);
        let empresa_div = document.getElementById("input_global-empresa");
        /* console.log(empresa_div); */
        empresa_div.innerText = myConstClass.VAR_GLOBAL.empresas[empresa].name;
        /* console.log(empresa); */
        if(empresa === 'brivet'){
            setIdentidad(user.brivetCod, user.brivetName);
            /* myConstClass.VAR_GLOBAL.cobrador = user.brivetCod;
            myConstClass.VAR_GLOBAL.cobrador_name = user.brivetName; */
        }
        if(empresa === 'fcb'){            
            setIdentidad(user.fcbCod, user.fcbName);
            /* myConstClass.VAR_GLOBAL.cobrador = user.fcbCod;
            myConstClass.VAR_GLOBAL.cobrador_name = user.fcbName; */
        }
        cargarDataGoToMain(empresa);
        /* navigate("/") */
    }

    return (
      <div className="container">
          <div className="row">
            <div className="col-12">                
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Cambiar empresa</h4>
                        <hr />                        
                        <Form onSubmit={changeEmpresa}>
                            <ButtonGroup size="lg">
                                <ToggleButton
                                    key={`1`}
                                    id={`default-radio-1`}
                                    type="radio"
                                    variant={'outline-primary'}
                                    name={`input_empresa`}
                                    value={`brivet`}
                                    checked={empresa === 'brivet'}
                                    onChange={(e) => setEmpresa(e.currentTarget.value)}
                                >
                                    {`BRIVET SRL`}
                                </ToggleButton>
                                <ToggleButton
                                    key={`2`}
                                    id={`default-radio-2`}
                                    type="radio"
                                    variant={'outline-primary'}
                                    name={`input_empresa`}
                                    value={`fcb`}
                                    checked={empresa === 'fcb'}
                                    onChange={(e) => setEmpresa(e.currentTarget.value)}
                                >
                                    {`FCB SRL`}
                                </ToggleButton>
                            </ButtonGroup>
                            <br />
                            {/* <div key={`default-radio`} className="mb-3">
                                <Form.Check 
                                    type="radio"
                                    id={`default-radio`}
                                    name={`input_empresa`}
                                    label={`BRIVET SRL`}
                                    value={`brivet`}
                                    checked={empresa === 'brivet'}
                                    onChange={e => setEmpresa(e.currentTarget.value)}
                                />
                                <Form.Check                            
                                    type="radio"
                                    id={`default-radio`}
                                    name={`input_empresa`}
                                    label={`FCB SRL`}
                                    value={`fcb`}
                                    checked={empresa === 'fcb'}
                                    onChange={e => setEmpresa(e.currentTarget.value)}
                                />
                            </div>    */}                         
                            <Button variant="primary" className="mt-2" block="block" type="submit">
                                <FontAwesomeIcon icon={faShop}/> Cambiar empresa
                            </Button>
                        </Form>
                    </div>   
                </div>
            </div>
          </div>
      </div>
    )
}
