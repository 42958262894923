import { Routes, Route, useNavigate } from 'react-router-dom';
import {useEffect } from 'react';
import Home from './home.component';
import Login from './login.component';

function Intro() {
    const navigate = useNavigate();
    
    useEffect(()=>{        
        navigate('/login');
    },[])
    
    return (
        <>
            <div className="container">
                <Routes>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </div>
        </>
    );
}

export default Intro;
