import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useNavigate, useParams } from 'react-router-dom'
import * as myConstClass from '../../constantes.js';
import AuthUser from '../AuthUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const MySwal = withReactContent(Swal)

export default function CambiarClave() {
    const {http, catchError, jsonSaLoading} = AuthUser();
    const { id } = useParams()
  const navigate = useNavigate();

  const [clave, setClave] = useState("")
  const [username, setUsername] = useState("")
  
  const [validationError,setValidationError] = useState({})

  useEffect(()=>{
    fetchProducts() 
  },[])

  const fetchProducts = async () => {    
    MySwal.fire(jsonSaLoading(MySwal));
    await http.get(`/mantenedor/users/${id}`).then(({data})=>{
        MySwal.close();
        const { _nombre, _email, _rol, _habilitado, _codBrivet, _codFcb, _tipoCliente, _username } = data.user.original ;
        setClave("")
        setUsername(_username)
    }).catch(({response:{data}})=>{
        catchError(data);
    })
  }


  const updateProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('clave', clave)

    MySwal.fire(jsonSaLoading(MySwal, "Modificando registro"));
    await http.post(`/mantenedor/users/${id}/cambiarClave`, formData).then(({data})=>{
        MySwal.fire({
            icon:"success",
            text:data.message,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
    }).catch(({response})=>{
        if(response.status===422){
            setValidationError(response.data.errors)
        }else{        
            catchError(response);
        }
    })
  }


  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Cambiar Clave Usuario</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>   
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={updateProduct}>
                  <Row className="my-3">                      
                    <Col>
                      <Form.Group controlId="inputUsername">
                          <Form.Label>Usuario</Form.Label>
                          <Form.Control type="text" value={username} readOnly onChange={(event)=>{
                            setUsername(event.target.value)
                          }}/>
                      </Form.Group>
                    </Col>
                      <Col>
                        <Form.Group controlId="inputClave">
                            <Form.Label>Clave</Form.Label>
                            <Form.Control type="password" value={clave} onChange={(event)=>{
                              setClave(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <hr />
                  <Button variant="primary" className="btn-sm mt-2" size="lg" block="block" type="submit">
                    Guardar
                  </Button>
                  <Link className='btn btn-outline-primary btn-sm mt-2 float-end' to={"/usuarios/"}>
                      Ver todos
                  </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
