import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom'
import * as myConstClass from '../../constantes.js';
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function CrearUsuario() {
  const {http, catchError, jsonSaLoading} = AuthUser();
  const navigate = useNavigate();

  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [rol, setRol] = useState("COBRADOR")
  const [habilitado, setHabilitado] = useState("0")
  const [codBrivet, setCodBrivet] = useState(0)
  const [codFcb, setCodFcb] = useState(0)
  const [tipoCliente, setTipoCliente] = useState("TODOS")
  const [username, setUsername] = useState("")
  const [clave, setClave] = useState("")
  
  const [validationError,setValidationError] = useState({})

  const [tablaBrivet, setTablaBrivet] = useState([])
  const [tablaFcb, setTablaFcb] = useState([])

  useEffect(()=>{
    fetchProducts() 
  },[])

  const fetchProducts = async () => {
    MySwal.fire(jsonSaLoading(MySwal));
    await http.get(`/getCobradores`).then(({data})=>{
      MySwal.close();
      setTablaBrivet(data.brivet)
      setTablaFcb(data.fcb)
    }).catch(({response:{data}})=>{
      catchError(data);
    })
  }


  const createData = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('nombre', nombre)
    formData.append('email', email)
    formData.append('rol', rol)
    formData.append('habilitado', habilitado)
    formData.append('codBrivet', codBrivet)
    formData.append('codFcb', codFcb)
    formData.append('tipoCliente', tipoCliente)
    formData.append('username', username)
    formData.append('clave', clave)
    
    MySwal.fire(jsonSaLoading(MySwal, "Guardando registro"));
    await http.post(`/mantenedor/users`, formData).then(({data})=>{
      MySwal.fire({
        icon:"success",
        text:data.message,        
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Regresar a la lista'
      }).then((result) => { 
        if(result.isConfirmed == true){            
          navigate("/usuarios")
        }
      })
    }).catch(({response})=>{
      if(response.status===422){
        setValidationError(response.data.errors)
      }else{        
        catchError(response);
      }
    })
  }

  const handleHabilitadoChange = e => {
    e.persist();
    setHabilitado(e.target.value);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Nuevo Usuario</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>   
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={createData}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="inputNombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" value={nombre} onChange={(event)=>{
                              setNombre(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="inputEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" value={email} onChange={(event)=>{
                              setEmail(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>                        
                        <Form.Group >                      
                          <Form.Label>Habilitado</Form.Label>                
                            <Row className="my-3">                        
                              <Col>
                                <Form.Check
                                    value="1"
                                    type="radio"
                                    aria-label="radio 2"
                                    label="SI"
                                    onChange={handleHabilitadoChange}
                                    checked={habilitado === "1"}
                                  />
                              </Col>
                              <Col>
                                <Form.Check
                                    value="0"
                                    type="radio"
                                    aria-label="radio 1"
                                    label="NO"
                                    onChange={handleHabilitadoChange}
                                    checked={habilitado === "0"}
                                  />
                              </Col>              
                              <Col></Col>  
                              <Col></Col>
                            </Row>
                        </Form.Group>
                      </Col>                      
                      <Col>
                        <Form.Group controlId="selectRol">
                              <Form.Label>Rol</Form.Label>
                              <Form.Control
                              as="select"
                              value={rol}
                              onChange={e => {
                                  let _documento = e.target.value;
                                  setRol(_documento)
                              }}
                              >
                                  <option key={`ADMINISTRADOR`} value={`ADMINISTRADOR`}>ADMINISTRADOR</option>
                                  <option key={`COBRADOR`} value={`COBRADOR`}>COBRADOR</option>
                              </Form.Control>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group controlId="selectBrivet">
                            <Form.Label>Trabajador Brivet</Form.Label>
                            <Form.Control
                            as="select"
                            value={codBrivet}
                            onChange={e => {
                                let _documento = e.target.value;
                                setCodBrivet(_documento)
                            }}
                            >
                                <option key={`0`} value={`0`}>Elija obligatoriamente</option>
                                {tablaBrivet.map((item, idx) => {                                            
                                    return (
                                      <option 
                                          key={idx} 
                                          value={item.id}
                                      > 
                                        {item.descripcion}
                                      </option>
                                    );
                                } )}
                            </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="selectFcb">
                            <Form.Label>Trabajador Fcb</Form.Label>
                            <Form.Control
                            as="select"
                            value={codFcb}
                            onChange={e => {
                                let _documento = e.target.value;
                                setCodFcb(_documento)
                            }}
                            >
                                <option key={`0`} value={`0`}>Elija obligatoriamente</option>
                                {tablaFcb.map((item, idx) => {                                            
                                    return (
                                      <option 
                                          key={idx} 
                                          value={item.id}
                                      > 
                                        {item.descripcion}
                                      </option>
                                    );
                                } )}
                            </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group controlId="selectTipoCliente">
                            <Form.Label>Tipo Cliente</Form.Label>
                            <Form.Control
                            as="select"
                            value={tipoCliente}
                            onChange={e => {
                                let _documento = e.target.value;
                                setTipoCliente(_documento)
                            }}
                            >
                                <option key={`TODOS`} value={`TODOS`}>TODOS</option>
                                <option key={`MAYORES`} value={`MAYORES`}>MAYORES</option>
                                <option key={`MENORES`} value={`MENORES`}>MENORES</option>
                            </Form.Control>
                      </Form.Group>                                
                    </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="inputUsername">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control type="text" value={username} onChange={(event)=>{
                              setUsername(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="inputClave">
                            <Form.Label>Clave</Form.Label>
                            <Form.Control type="password" value={clave} onChange={(event)=>{
                              setClave(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <hr />
                  <Button variant="primary" className="btn-sm mt-2" size="lg" block="block" type="submit">
                    Guardar
                  </Button>
                  <Link className='btn btn-outline-primary btn-sm mt-2 float-end' to={"/usuarios/"}>
                      Regresar a usuarios
                  </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
