import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBills } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthUser from '../AuthUser';
import {useReactToPrint} from 'react-to-print'
import "../../App.css";

const MySwal = withReactContent(Swal)

export default function ListClientesEstadoCuenta() {

    const {http, catchError, jsonSaLoading, user, identidad,  base} = AuthUser();
    
    const { id, namecliente } = useParams()

    const [datosTabla, setDatosTabla] = useState([])
    const [tablaShow, setTablaShow] = useState([])
    
    const [searchInicio, setSearchInicio] = useState(new Date());
    const [searchFin, setSearchFin] = useState(new Date());
    const [searchTipo, setSearchTipo] = useState("TODOS")

    const printRef = useRef();
    const imprimirDoc = useReactToPrint({
        content: () => printRef.current,
        documentTitle : `${new Date().getDate()}-${(new Date().getMonth())+ 1 }-${new Date().getFullYear()}-${document.title}`,
        /* onAfterPrint: () => alert('Print success') */
    });
    const printRef2 = useRef();
    const imprimirDoc2 = useReactToPrint({
        content: () => printRef2.current,
        documentTitle : `${new Date().getDate()}-${(new Date().getMonth())+ 1 }-${new Date().getFullYear()}-${document.title}`,
        /* onAfterPrint: () => alert('Print success') */
    });

    const buscar = () => {        
        /* console.log(cod); */
        const results = datosTabla.filter((item) => {            
            let __date = new Date(item.d_fecha);
            return  ( 
                        ( (searchTipo === "TODOS") || (searchTipo === "SALDO" && parseFloat(item.saldoSoles) > 0)) &&
                        ( __date >= searchInicio && __date <= searchFin )
                    );
        });        
        setTablaShow(results);
    }

    const todosDatos = () => {        
        /* console.log(cod); */
        setTablaShow(datosTabla);
    }
    

    useEffect(()=>{
        document.title = `Estado de Cuenta ${namecliente}`;
        fetchDocumentos() 
        /* console.log(datosTabla); */
    },[])

    const fetchDocumentos = async (varPage = 1) => {
        MySwal.fire(jsonSaLoading(MySwal, "Cargando datos."));
        await http.get(`/${base}/getDocumentosCliente/${id}`).then(({data})=>{
            let tabla    = data.tabla ;
            let pagos = data.pagos;
            /* console.log(data.tabla); */
            let nuevoArray    = [] ;            
            for(var i=0; i<tabla.length; i++){
                if(parseFloat(tabla[i]["d_total"]) * 1 !== 0){
                    /* console.log(parseFloat(tabla[i]["d_total"])); */
                    nuevoArray.push(tabla[i]);
                }
            }
            const newdata = nuevoArray.map(item => { 
                let doc = item.doc;
                doc = doc.replace(/\./g,"")
                let serie = doc+"01";
                let _numero = item.v_numeroDocumento;
                let _full = serie + "-"+ myConstClass.zeroPad(_numero, 8);                
                let array_pagos    = [] ;
                for(var i=0; i<pagos.length; i++){
                    /* console.log(pagos[i]["v_numeroDocumento"] , pagos[i]["cod_doc"]); */
                    if(pagos[i]["v_numeroDocumento"] == _numero && pagos[i]["cod_doc"] == item.i_iddocumento ){
                        /* console.log(parseFloat(tabla[i]["d_total"])); */
                        array_pagos.push(pagos[i]);                        
                    }
                }
                return {
                  ...item,
                    documento_string: _full,
                    pagos : array_pagos
                };
            });
            console.log(newdata);
            MySwal.close();
            setDatosTabla(newdata)
            setTablaShow(newdata)
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    const IconoColumna = (props) => {
        if(props.fila.saldoSoles == 0){
            return (                
            	<span>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
            )
        }else{
            return (     
            	<span>
                    <FontAwesomeIcon icon={faMoneyBills} />
                </span>
            )
        }
    }

    const columnsDeuda = [        
        /* {
            id: 'index',
            name: 'i',
            maxWidth:'15px !important',
            minWidth:'15px !important',
            selector: row => parseInt(row.indexTemp),
            center: true,
            compact:true,
        }, */      
        {
            id: 'document',
            name: 'Documento ',
            maxWidth:'110px !important',
            minWidth:'110px !important',
            selector: row => row.documento_string,
            cell: row => (
            	<div>
                    <span className="title_cell_table"><IconoColumna fila={row} /> {row.documento_string}</span>
            	</div>
            ),
            center: true,
            sortable: true,
            reorder: true,   
            style: {
            	"padding-left": "0px",                
            	"padding-right": "0px"
            },
        },
        {
            id: 'fecha',
            name: 'Fecha Doc',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => row.d_fecha,
            cell: row => (
            	<span>
                    {myConstClass.get_date(row.d_fecha)}
                </span>
            ),
            center: true,
            sortable: true,
            reorder: true,   
            compact:true,
        },
        {
            id: 'total',
            name: 'Total',
            maxWidth:'90px !important',
            minWidth:'90px !important',
            selector: row => parseFloat(myConstClass.round(row.d_total, 2)),            
            cell: row => (
                <span> S/. {myConstClass.round_string(row.d_total, 2)}</span>
            ),     
            right: true,
            sortable: true,
            reorder: true,   
            style: {
            	"padding-left": "8px",                
            	"padding-right": "8px"
            },
        },
        {
            id: 'deuda',
            name: 'Saldo',
            maxWidth:'90px !important',
            minWidth:'90px !important',
            selector: row => parseFloat(myConstClass.round(row.saldoSoles, 2)),
            cell: row => (
                <span id={`cell_deuda-`+ String(row.numDoc).replace(/ /g, "") }> S/. {myConstClass.round_string(row.saldoSoles, 2)}</span>
            ),   
            sortable: true,
            reorder: true,     
            right: true,
            style: {
            	"padding-left": "8px",                
            	"padding-right": "8px"
            },
        }
    ];

    const ExpandedComponent = ({ data }) => 
    <> 
        <div style={{width:'100%', display: 'block', float: 'right', overflow: 'hidden'}}>            
            <div className="row">
                <div className="col-2"></div>
                <div className="col-10">
                    {
                        data.pagos.length > 0 && (                    
                    <table className="table table2 table-bordered mb-0 text-center">
                        <thead>
                            <tr key={`c4`}>
                                <th>Recibo</th>
                                <th>Fecha Pago</th>
                                <th>Monto Pagado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.pagos.length > 0 && (
                                    data.pagos.map((arow, akey)=>(
                                        <tr key={akey}>
                                            <td>
                                                { arow.recibo_serie != null ? arow.recibo_full : "NN" }
                                            </td>
                                            <td className="text-center">
                                                {myConstClass.get_date(arow.d_fechaDoc)}
                                            </td>
                                            <td className="text-end">S/ {arow.d_Total}</td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                        )
                    }
                </div>
                <div className="col-12">
                    <br />
                </div>
            </div>            
        </div>
    </>
    ;

    const conditionalRowStyles = [
        {
          when: row => parseFloat(row.saldoSoles) > 0,
          style: {
            backgroundColor: "rgb(255 143 143)",
            userSelect: "none",
            color: "#000",
          }
        }
    ];

    

    
    return (
        <div className="container">
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    @media print {
                        main {
                            padding-bottom: 50px;
                            padding-left: 30px;
                            padding-right: 30px;
                            /* height: .5in !important; */
                        }
                    }
                    
                    header, main, footer {
                        width: 100%;
                    }
                    
                    table th,
                    table td{
                        padding: 3px !important;
                    }
                    
                    header {
                        /* position: absolute !important; */
                        top: 0 !important;
                        left: 0 !important;
                    }
                    
                    @media print {
                        *{
                            line-height: 1.4;
                            font-size: 12px;
                        }
                    
                        .borde th
                        ,.borde td{
                            border: 1px solid #000;
                        }
                    
                        
                        .borde-empty {
                            border: 0px solid #000 !important;
                        }
                    
                        header{        
                            padding-top: 30px;
                        }
                    
                        header, footer {
                            /* position: fixed !important; */
                            padding-left: 30px;
                            padding-right: 30px;
                        }
                    
                        footer{
                    
                        }
                    
                        .containerv1{
                            display:block;
                            position:relative;
                        }
                    
                        h1{
                            font-size: 20px;        
                            margin: 0px;
                        }
                    
                        h5{
                            font-size: 14px;
                            margin: 0px;
                        }
                    
                        .w-100{
                            width: 100%;
                        }
                    
                        .text-center{
                            text-align: center;
                        }
                    
                        .mini{
                            font-size:9px;
                            line-height: 0px !important;
                        }
                        
                        footer {
                            bottom: 0 !important;
                            padding-left: 30px;
                            padding-left: 30px;
                        }
                    
                        .bg-test{
                            background-color: rgba(0, 0, 0, 0.416);
                        }    
                    
                        table.datos{
                            margin-top: 15px;
                        }
                        
                        table.report-container {
                            page-break-after:always;
                        }
                        thead.report-header {
                            display:table-header-group;
                        }
                        tfoot.report-footer {
                            display:table-footer-group;
                        } 
                        table.report-container table.datos {
                            page-break-inside: avoid;    
                            /* page-break-inside: avoid;    
                            counter-increment: page !important; */
                            /* page-break-before: always; */ /*This style rule makes every page element start at the top of a new page:*/
                            counter-increment: page;
                        }
                        
                        .hide-element{
                            display:none !important;
                        }
                    
                        #page-number:after{        
                            content:counter(page);
                        }    
                    }`
                }}
            />
            <h4 className="text-center">Estado de cuenta</h4>
            <h3 className="text-center">{namecliente} </h3>                           
            <div className="row">                
                <div className="col-6">
                    <Form.Group controlId="fechaInicio">
                        <Form.Label>Desde:</Form.Label>
                        <DatePicker 
                            className="form-control mb-2" dateFormat="dd/MM/yyyy"
                            selected={searchInicio}
                            onChange={(date) => setSearchInicio(date)}
                        />
                    </Form.Group>
                </div>
                <div className="col-6">                
                    <Form.Group controlId="fechaFin">
                        <Form.Label>Hasta:</Form.Label>
                        <DatePicker 
                            className="form-control mb-2" dateFormat="dd/MM/yyyy"
                            selected={searchFin}
                            onChange={(date) => setSearchFin(date)}
                        />
                    </Form.Group>
                </div>                
                <div className="col-6">
                    <Form.Group controlId="selectFecha">
                        <Form.Label>Tipo:</Form.Label>
                        <Form.Control
                            as="select"
                            value={searchTipo}
                            className="mb-2"
                            onChange={e => {
                                let _val = e.target.value;
                                setSearchTipo(_val)
                            }}
                        >
                            <option key={`TODOS`} value={`TODOS`}>Todos los documentos</option>
                            <option key={`SALDO`} value={`SALDO`}>Solo con saldo pendiente</option>
                        </Form.Control>
                    </Form.Group>
                </div>                
                <div className='col-6'>                        
                    <Form.Group >                        
                        <Form.Label> </Form.Label> <br />
                        <Button 
                            variant="success" 
                            className="mt-2 btn-sm" 
                            size="lg" block="block"                             
                            onClick={() => {
                                buscar();
                            }}
                            type="button">
                            <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar
                        </Button>
                        <Button 
                            variant="primary" 
                            className="mt-2 btn-sm" 
                            size="lg" block="block"                             
                            onClick={() => {
                                todosDatos();
                            }}
                            type="button">
                                Todos
                        </Button>
                        <Button 
                            variant="outline-success" 
                            className="mt-2 btn-sm" 
                            size="lg" block="block" 
                            type="button"
                            onClick={() => {
                                imprimirDoc();
                            }}
                        >
                            <FontAwesomeIcon icon={faPrint} /> Impr Completo
                        </Button>
                        <Button 
                            variant="outline-success" 
                            className="mt-2 btn-sm" 
                            size="lg" block="block" 
                            type="button"
                            onClick={() => {
                                imprimirDoc2();
                            }}
                        >
                            <FontAwesomeIcon icon={faPrint} /> Impr Resumen
                        </Button>
                    </Form.Group>
                </div>
            </div>
            <hr />
            <div className="row">
                {/* <div className='col-12'>
                    <Link className='btn btn-primary mb-2 float-end' to={"/tipopago/create"}>
                        Nuevo
                    </Link>
                </div> */}
                <div className="col-12">
                    <div className="table-responsive">                    
                        <DataTable
                            columns={columnsDeuda}
                            data={tablaShow}      
                            data-tag="allowRowEvents"
                            conditionalRowStyles={conditionalRowStyles}
                            expandableRows 
                            expandableRowsComponent={ExpandedComponent}
                            expandableRowExpanded={row => row.pagos.length > 0 ? true : false }
                            /* onRowClicked={handleRowClicked} */
                        />
                        <br />
                    </div>
                </div>
            </div>          
            <section id="print" style={{display: 'none'}}>
                <div 
                    ref = {printRef} 
                    style={{
                        width: '100%', 
                        height: window.innerHeight
                    }}
                >
                    <table className="report-container w-100">
                        <thead className="report-header">
                            <tr key={`content1`}>
                                <th className="report-header-cell">
                                    <div className="header-info">
                                        <header>
                                            <div className="containerv1">                            
                                                <table className="w-100">
                                                    <tbody>
                                                        <tr key={`content6`}>
                                                            <td style={{width:'20%'}}>
                                                                <span className="mini">{myConstClass.VAR_GLOBAL.empresas[base].fullname}</span> <br />
                                                                <span className="mini">SISTEMA ADMINISTRATIVO PARA LA GESTIÓN DE COBROS</span> 
                                                            </td>
                                                            <td style={{width:'40%'}}>
                                                                <h5 className="text-center">ESTADO DE CUENTA</h5>
                                                                <h1 className="text-center">{namecliente}</h1>
                                                            </td>
                                                            <td style={{width:'20%', 'textAlign':'right' }}>
                                                                <span className="mini">{user.username}</span> <br />
                                                                <span className="mini">Impreso : {new Date().toLocaleString() + ""}</span> <br />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                        </header>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tfoot className="report-footer">
                            <tr key={`content2`}>
                                <td className="report-footer-cell">
                                    <div className="footer-info">
                                        <div className="text-center">
                                            <div className="pagina">                                                
                                                {/* Página <span id="page-number"></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                        <tbody className="report-content">
                            <tr key={`content`}>
                                <td className="report-content-cell">     
                                    <main className="">
                                        <div className="containerv1">
                                            {
                                                tablaShow.length > 0 && (
                                                    tablaShow.map((row, key)=>(
                                                        <>
                                                        <table key={key} className="w-100 borde datos">
                                                            <thead>
                                                                <tr key={"a" + key}>
                                                                    <th>Documento</th>
                                                                    <th>Fecha</th>
                                                                    <th>Dias</th>
                                                                    <th>Moneda</th>
                                                                    <th>Total</th>
                                                                    <th>Saldo</th>
                                                                    <th>Pagado</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr key={`dat1a`}>
                                                                    <td >{row.documento_string}</td>
                                                                    <td className="text-center">{myConstClass.get_date(row.d_fecha)}</td>
                                                                    <td className="text-center">{row.dias}</td>
                                                                    <td style={{width:'8%'}} className="text-center">SOLES</td>
                                                                    <td style={{width:'12%'}} className="text-end">S/ {myConstClass.round_string(row.d_total)}</td>
                                                                    <td style={{width:'12%'}} className="text-end">S/ {myConstClass.round_string(row.saldoSoles)}</td>
                                                                    <td style={{width:'12%'}} className="text-end">S/ {myConstClass.round_string(row.pagado)}</td>
                                                                </tr>                                                
                                                                <tr key={`dat2a`}>
                                                                    <td colSpan="7" style={{height:'5px'}}  className="borde-empty">
                                                                    </td>
                                                                </tr>
                                                                <tr key={`dat3a`} className={ row.pagos.length > 0 ? `` : `hide-element`} >
                                                                    <td colSpan="4" className="borde-empty"></td>
                                                                    <td style={{width:'12%'}} >
                                                                        Recibo
                                                                    </td>
                                                                    <td style={{width:'12%'}} >
                                                                        Fecha
                                                                    </td>
                                                                    <td style={{width:'12%'}} >
                                                                        Pagado
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    row.pagos.length > 0 && (
                                                                        row.pagos.map((pago, pkey)=>(                                                                                           
                                                                <tr key={`dat${pkey}`}>
                                                                    <td colSpan="4" className="borde-empty"></td>
                                                                    <td style={{width:'12%'}}>
                                                                        { pago.recibo_serie != null ? pago.recibo_full : "NN" }
                                                                    </td>
                                                                    <td style={{width:'12%'}} className="text-center">
                                                                        {myConstClass.get_date(pago.d_fechaDoc)}
                                                                    </td>
                                                                    <td style={{width:'12%'}} className="text-end">
                                                                        S/ {myConstClass.round_string(pago.d_Total)}
                                                                    </td>
                                                                </tr>                                                              
                                                                        ))
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </main>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section id="print2" style={{display: 'none'}}>
                <div 
                    ref = {printRef2} 
                    style={{
                        width: '100%', 
                        height: window.innerHeight
                    }}
                >
                    <table className="report-container w-100">
                        <thead className="report-header">
                            <tr key={`content1`}>
                                <th className="report-header-cell">
                                    <div className="header-info">
                                        <header>
                                            <div className="containerv1">                            
                                                <table className="w-100">
                                                    <tbody>
                                                        <tr key={`content6`}>
                                                            <td style={{width:'20%'}}>
                                                                <span className="mini">{myConstClass.VAR_GLOBAL.empresas[base].fullname}</span> <br />
                                                                <span className="mini">SISTEMA ADMINISTRATIVO PARA LA GESTIÓN DE COBROS</span> 
                                                            </td>
                                                            <td style={{width:'40%'}}>
                                                                <h5 className="text-center">ESTADO DE CUENTA</h5>
                                                                <h1 className="text-center">{namecliente}</h1>
                                                            </td>
                                                            <td style={{width:'20%', 'textAlign':'right' }}>
                                                                <span className="mini">{user.username}</span> <br />
                                                                <span className="mini">Impreso : {new Date().toLocaleString() + ""}</span> <br />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                        </header>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tfoot className="report-footer">
                            <tr key={`content2`}>
                                <td className="report-footer-cell">
                                    <div className="footer-info">
                                        <div className="text-center">
                                            <div className="pagina">                                                
                                                {/* Página <span id="page-number"></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                        <tbody className="report-content">
                            <tr key={`content`}>
                                <td className="report-content-cell">     
                                    <main className="">
                                        <div className="containerv1">
                                            <table key={`KEY1`} className="w-100 borde datos">
                                                <thead>
                                                    <tr key={"aaa"}>
                                                        <th>Documento</th>
                                                        <th>Fecha</th>
                                                        <th>Dias</th>
                                                        <th>Moneda</th>
                                                        <th>Total</th>
                                                        <th>Saldo</th>
                                                        <th>Pagado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tablaShow.length > 0 && (
                                                            tablaShow.map((row, key)=>(                                                
                                                                <tr key={`dat1a`}>
                                                                    <td >{row.documento_string}</td>
                                                                    <td className="text-center">{myConstClass.get_date(row.d_fecha)}</td>
                                                                    <td className="text-center">{row.dias}</td>
                                                                    <td style={{width:'8%'}} className="text-center">SOLES</td>
                                                                    <td style={{width:'12%'}} className="text-end">S/ {myConstClass.round_string(row.d_total)}</td>
                                                                    <td style={{width:'12%'}} className="text-end">S/ {myConstClass.round_string(row.saldoSoles)}</td>
                                                                    <td style={{width:'12%'}} className="text-end">S/ {myConstClass.round_string(row.pagado)}</td>
                                                                </tr>
                                                            ))
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </main>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    )
}
