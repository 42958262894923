import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faKey } from '@fortawesome/free-solid-svg-icons';
import AuthUser from '../AuthUser';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const MySwal = withReactContent(Swal)

export default function List() {
    const {http, catchError, jsonSaLoading, base} = AuthUser();
    const [datosTabla, setDatosTabla] = useState([])

    useEffect(()=>{
        fetchUsuarios() 
    },[])



    const fetchUsuarios = async () => {
        MySwal.fire(jsonSaLoading(MySwal, 'Cargando datos actualizados'));
        await http.get(`/mantenedor/users`).then(({data})=>{
            MySwal.close();
            setDatosTabla(data.data)
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    const actualizarPermisoEspecial = async (_cob) => {
        MySwal.fire(jsonSaLoading(MySwal, 'Realizando cambios en permisos'));
        await http.get(`/setPermisoEspecialOnOFF/${_cob}`).then(({data})=>{
            MySwal.close();
            fetchUsuarios();
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    /* const deleteProduct = async (id) => {
        const isConfirm = await Swal.fire({
            title: 'Desea continuar?',
            text: "Esta accion no puede ser revertida",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar.'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }

          await axios.delete(`${myConstClass.URL_APP}/api/${base}/products/${id}`).then(({data})=>{
            Swal.fire({
                icon:"success",
                text:data.message
            })
            fetchUsuarios()
          }).catch(({response:{data}})=>{
            catchError(data);
          })
    } */

    return (
      <div className="container">        
            <h4 className="text-center">Usuarios</h4>        
            <hr />
          <div className="row">
            <div className='col-12'>
                <Link className='btn btn-primary mb-2 float-end' to={"/usuarios/create"}>
                    Nuevo usuario
                </Link>
            </div>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-bordered mb-0 text-center">
                        <thead>
                            <tr>
                                <th></th>
                                {/* <th>Nombre</th> */}
                                <th>User</th>
                                <th>Rol</th>
                                <th>Ult. Acc.</th>
                                <th>Perm.Esp.</th>
                                <th>Hab?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                datosTabla.length > 0 && (
                                    datosTabla.map((row, key)=>(
                                        <tr key={key}>
                                            <td>
                                                <DropdownButton drop="end" size="sm" variant="outline-primary" menuVariant="dark" id="dropdown-basic-button" title="">
                                                    <Dropdown.Item href={`/usuarios/edit/${row.id}`}><FontAwesomeIcon icon={faPenToSquare}/> Editar</Dropdown.Item>
                                                    <Dropdown.Item href={`/usuarios/password/${row.id}`}><FontAwesomeIcon icon={faKey}/> Cambiar Clave</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            {/* <td className="text-start">{row.name}</td> */}
                                            <td className="text-start">{row.username}</td>
                                            <td className="text-start">{row.rol}</td>
                                            <td className="text-start">{row.last_login}</td>
                                            <td>
                                                <button 
                                                    className={`btn ${row.permiso_especial == "1" ? 'btn-link' : 'btn-primary' } btn-sm`}
                                                    onClick={e => {
                                                        /* verPagos(); */
                                                        actualizarPermisoEspecial(row.id);
                                                    }}
                                                    >                                                    
                                                    {row.permiso_especial == "1" ? "SI" : "NO" }
                                                </button>
                                            </td>
                                            <td>{row.habilitado === "1" ? "SI" : "NO" }</td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
      </div>
    )
}
