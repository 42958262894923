import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DataTable from 'react-data-table-component';
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareUpRight } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AuthUser from '../AuthUser';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useReactToPrint} from 'react-to-print'
import jsPDF from "jspdf"
import html2canvas from 'html2canvas'
import "../../App.css";

const MySwal = withReactContent(Swal)

export default function ListPagados() {
    const {http, catchError, jsonSaLoading, user, identidad, base} = AuthUser();
    const [documentos, setDocumentos] = useState([])
    const [tableShow, setTableShow] = useState([])
    const [dataCobradores, setDataCobradores] = useState([])
    const [name, setName] = useState('');    

    const [tituloExport, setTituloExport] = useState('titulo_exportacion.pdf')
    
    const [omitirColumnaCobrador, setOmitirColumnaCobrador] = useState(true);
    
    const [searchCliente, setSearchCliente] = useState('');
    const [searchInicio, setSearchInicio] = useState(new Date());
    const [searchFin, setSearchFin] = useState(new Date());
    const [searchTipoFecha, setSearchTipoFecha] = useState('d_fechaPago');
    const [searchCobrador, setSearchCobrador] = useState(identidad.cobrador);

    const [ efectivo , setEfectivo ] = useState([])

    
    

    const [doc, setDoc ] = useState({})

    const [modalEditar, setModalEditar] = useState(false)

    const [dataPrint, setDataPrint] = useState([]);

    const printRef = useRef();
    const imprimirDoc = useReactToPrint({
        content: () => printRef.current,
        documentTitle : 'emp-data',
        /* onAfterPrint: () => alert('Print success') */
    });

    const generatePDF = (_name) => {
        /* console.log(document.getElementById("exportPDF").innerHTML); */
        let htmlElement = document.getElementById("exportPDF");
        let doc = new jsPDF('p', 'pt', [260, 300]);
        doc.html(htmlElement, {
            callback: function (pdf) {
                borrarEmptyPages(pdf);

                pdf.save(_name);
            }
        });
        /* doc.fromHTML(string);
        doc.save(_name); */
        /* htmlElement.style.visibility = 'hidden'; */
        /* htmlElement.style.visibility = 'visible';
        html2canvas(htmlElement, {width: 200,
            height: 250}).then((canvas) => {
            //document.body.appendChild(canvas); // if you want see your screenshot in body.
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt', [200, 280]); //set pdf size to 8.5in x 11in Portrait
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(_name);
        });
        htmlElement.style.visibility = 'hidden'; */
    }
    const borrarEmptyPages = (pdf) => {
        let pageCount = pdf.internal.getNumberOfPages();
        for (let index = 1; index <= pageCount; index++) {
            if(index > 1){
                console.log("borrado ", index);
                pdf.deletePage(index);
            }
        }
        if(pdf.internal.getNumberOfPages() > 2){
            borrarEmptyPages(pdf);
        }
    }
    const imprimirDocumento = async (codPrint) => {
        MySwal.fire(jsonSaLoading(MySwal, "Preparando para imprimir"));
        await http.get(
            `/${base}/getPagoToPrint/${codPrint}`
        ).then(({data})=>{            
            setDataPrint(data.data);
            MySwal.fire({
                icon:"success",
                text: "Impresion lista",
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ver impresion'
            }).then((result) => { 
                if(result.isConfirmed == true){
                    imprimirDoc();
                }
            });         
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    const descargarPdf = async (codPrint) => {
        MySwal.fire(jsonSaLoading(MySwal, "Preparando pdf"));
        await http.get(
            `/${base}/getPagoToPrint/${codPrint}`
        ).then(({data})=>{            
            setDataPrint(data.data);
            let _cadena = "";
            data.data.forEach(function(item, i){
                _cadena =  `${item.recibo_serie}-${item.recibo_numero}_${item.cliente}`;
            });

            /* setTituloExport("Exportrando_documento.pdf"); */
            MySwal.fire({
                icon:"success",
                text: "Pdf generado",
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Descargar ahora'
            }).then((result) => { 
                if(result.isConfirmed == true){
                    /* console.log(tituloExport); */
                    generatePDF(_cadena);
                }
            });         
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }
    
    const handleCloseModalEditar = () => {
        setModalEditar(false);
        /* resetFormPago(); */
    }

    const verPago = (cod) => {        
        /* console.log(cod); */
        tableShow.forEach(function(item, i){
            if(item.akey == cod ){
                /* console.log(item); */
                setDoc(item);
                return;
            }
        });
        handleShowModalEditar();
    }

    const handleShowModalEditar = () => {
        setModalEditar(true);
    }

    useEffect(()=>{        
        document.title = "Mis cobros";
        fetchResults();
        var _data = JSON.parse(localStorage.getItem('dataCobradores'));
        setDataCobradores(_data);
        if(user.rol === "ADMINISTRADOR"){
            setOmitirColumnaCobrador(false);
        }
    },[])

    const fetchResults = async () => {
        MySwal.fire(jsonSaLoading(MySwal));
        const formData = new FormData()        
        formData.append('fecha_inicio_registrado', new Date(searchInicio).toLocaleDateString('en-CA'))
        formData.append('fecha_fin_registrado', new Date(searchFin).toLocaleDateString('en-CA'))
        formData.append('cliente', searchCliente)
        formData.append('tipofecha', searchTipoFecha)
        if(user.rol === "ADMINISTRADOR"){
            formData.append('cobrador', searchCobrador)
        }
        await http.post(
            `/${base}/getPagosCobrador`, 
            formData
        ).then(({data})=>{
            MySwal.close();
            /* console.log(data); */
            let __efectivo = 0;
            data.forEach(function(item, i){
                if(item.tipopago_string === "EFECTIVO"){
                    __efectivo = __efectivo  +  parseFloat(item.monto);
                    return ;
                }
            });
            setEfectivo(__efectivo);
            setTableShow(data);
        }).catch(({response:{data}})=>{
            catchError(data);
        });
    }

    const searchResults = async (e) => {        
        e.preventDefault();
        fetchResults();
    }
    

    const confirmarPago = async (e) => {
        e.preventDefault();
        return ;
        const isConfirm = await MySwal.fire({
          title: 'Desea continuar?',
          text: "Esta por APROBAR este pago",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelmButtonText: 'Regresar',
          confirmButtonText: 'Si, aprobar ahora!'
        }).then((result) => {
          return result.isConfirmed
        });
        if(!isConfirm){
          return;
        }
        MySwal.fire(jsonSaLoading(MySwal, "Confirmando pago"));
        await http.post(
            `${myConstClass.URL_APP}/api/${base}/setPagoAprobado/${doc.akey}`
        ).then(({data})=>{
            MySwal.fire({
                text:data.message,
                icon:"success"
            })
            handleCloseModalEditar();
            fetchResults();          
        }).catch(({response})=>{                
            console.log("ERROR");
            if(response.status===422){
                MySwal.fire({
                    text:response.data.message,
                    icon:"error"
                    })
            }else{
                MySwal.fire({
                text:response.data.message,
                icon:"error"
                })
            }
        })
    }

    function ImagenModal(props) {
        if(doc.imagen != ""){
            return (            
                <img className="img-pago w-100" src={`${myConstClass.URL_APP}/storage/pagos/${doc.imagen}`} alt="" />                
            );            
        }
    }

    const columns = [
        {
            id: 'action',
            name: '',
            maxWidth:'30px !important',
            minWidth:'initial !important',
            compact:true,
            cell: row => (
                <DropdownButton drop="end" size="sm" variant="outline-primary" menuVariant="dark" id="dropdown-basic-button" title="">
                    <Dropdown.Item 
                        as="button" 
                        onClick={() => {
                            verPago(row.akey)
                        }}
                    >
                        <FontAwesomeIcon icon={faSquareUpRight} /> Ver
                    </Dropdown.Item>
                    <Dropdown.Item 
                        as="button" 
                        onClick={() => {
                            imprimirDocumento(row.akey)
                        }}
                    >
                        <FontAwesomeIcon icon={faPrint} /> Imprimir
                    </Dropdown.Item>
                    <Dropdown.Item 
                        as="button" 
                        onClick={() => {
                            descargarPdf(row.akey)
                        }}
                    >
                        <FontAwesomeIcon icon={faPrint} /> PDF
                    </Dropdown.Item>
                </DropdownButton>
            ),
        },
        {
            id: 'cliente',
            name: 'Cliente',
            maxWidth:'205px !important',
            width:'205px !important',
            minWidth:'205px !important',
            selector: row => row.cliente,
            cell: row => (
            	<div>
                    <strong><span className="title_cell_table">{row.cliente}</span></strong>
                    <br />
                    {row.cliente_doc} {row.cliente_numero} 
            	</div>
            ),
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "10px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'fecha',
            name: 'Operacion',
            maxWidth:'85px !important',
            width:'85px !important',
            minWidth:'190px !important',
            selector: row => row.fecha_operacion,
            cell: row => (
                <span> { row.fecha_operacion ? myConstClass.get_date(row.fecha_operacion) : '-' }  </span>
            ),
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'monto',
            name: 'Monto',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => parseFloat((Math.floor(row.monto * 100)/100).toFixed(2)),            
            cell: row => (
                <span> S/. {(Math.floor(row.monto * 100)/100).toFixed(2)}</span>
            ),
            sortable: true,            
            right: true,
            grow:2,
            reorder: true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'codRecibo',
            name: 'Numero',
            maxWidth:'120px !important',
            width:'120px !important',
            minWidth:'120px !important',
            selector: row => row.recibo_full,
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'fechaRegistro',
            name: 'Registro',
            maxWidth:'85px !important',
            width:'85px !important',
            minWidth:'190px !important',
            selector: row => row.fecha_emision,
            cell: row => (
                <span> { row.fecha_emision ? myConstClass.get_date(row.fecha_emision) : '-' }  </span>
            ),
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'tipopago',
            name: 'Tipo Pago',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => row.tipopago_string,
            sortable: true,            
            center: true,
            grow:2,
            reorder: true,   
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'destino',
            name: 'Caja',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => row.caja_str,
            sortable: true,
            center: true,
            grow:1,
            reorder: true,
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'colDoc',
            name: 'Documento',
            maxWidth:'85px !important',
            width:'85px !important',
            minWidth:'190px !important',
            selector: row => row.documento_str,
            sortable: false,
            reorder: false,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'colCobrador',
            name: 'Cobrador',
            maxWidth:'220px !important',
            width:'220px !important',
            minWidth:'220px !important',
            selector: row => row.cobrado_por,
            sortable: true,
            center: true,
            omit: omitirColumnaCobrador,
            wrap:true,
            grow:1,
            reorder: true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        }
    ];
    /* ,
        {
            id: 'codinterno',
            name: 'COD-INTERNO',
            selector: row => row.key_agrupado,
            sortable: true,
            center: true,
            grow:1,
            reorder: true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'montooperacion',
            name: 'Operac.',
            maxWidth:'70px !important',
            minWidth:'70px !important',
            selector: row => parseFloat((Math.floor(row.monto_agrupado * 100)/100).toFixed(2)),            
            cell: row => (
                <span> S/. {(Math.floor(row.monto_agrupado * 100)/100).toFixed(2)}</span>
            ),
            sortable: true,            
            right: true,
            grow:2,
            reorder: true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "10px"
            },
        }, */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const DivCobrador = () => {
        if(user.rol === "ADMINISTRADOR"){
            return (
                <div className="col-12">
                    <Form.Group controlId="selectCobrador">
                        <Form.Label>Cobrador</Form.Label>
                        <Form.Control
                        as="select"
                        value={searchCobrador}
                        className="mb-2"
                        onChange={e => {
                            let _documento = e.target.value;
                            setSearchCobrador(_documento)
                        }}
                        >
                            <option key={`all`} value={`ALL`}>{`TODOS`}</option>
                            {dataCobradores.map((item, idx) => {
                                return (
                                    <option 
                                        key={idx} 
                                        value={item.id}
                                    > 
                                        {item.descripcion}
                                    </option>
                                );
                            } )}
                        </Form.Control>
                    </Form.Group>
                </div>
            )          
        }else{
            return (
                <div className="col-12"></div>
            )
        }        
    }
    return (
      <div className="container">
            <h3>Mis Cobros</h3>
            <hr />            
            <Form onSubmit={searchResults}>                
                <div className="row">
                    <DivCobrador />
                    <div className="col-4">
                        <Form.Group controlId="selectFecha">
                            <Form.Label>Fecha:</Form.Label>
                            <Form.Control
                                as="select"
                                value={searchTipoFecha}
                                className="mb-2"
                                onChange={e => {
                                    let _val = e.target.value;
                                    setSearchTipoFecha(_val)
                                }}
                            >
                                <option key={`operacion`} value={`operacion`}>Operación</option>
                                <option key={`registro`} value={`registro`}>Registro</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-4">
                        <Form.Group controlId="fechaInicio">
                            <Form.Label>Desde:</Form.Label>
                            <DatePicker 
                                className="form-control mb-2" dateFormat="dd/MM/yyyy"
                                selected={searchInicio}
                                onChange={(date) => setSearchInicio(date)}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-4">                
                        <Form.Group controlId="fechaFin">
                            <Form.Label>Hasta:</Form.Label>
                            <DatePicker 
                                className="form-control mb-2" dateFormat="dd/MM/yyyy"
                                selected={searchFin}
                                onChange={(date) => setSearchFin(date)}
                            />
                        </Form.Group>
                    </div>            
                    <div className='col-10'>
                        <Form.Group controlId="inputCliente">
                            <Form.Label>Cliente</Form.Label>
                            <Form.Control 
                                type="search"                         
                                value={searchCliente}
                                className=" mb-2"
                                onChange={(event)=>{setSearchCliente(event.target.value)}}
                            />
                        </Form.Group>
                    </div>                    
                    <div className='col-2'>                        
                        <Form.Group >
                            <Form.Label> </Form.Label> <br />
                            <Button variant="success" className="mt-2 btn-sm" size="lg" block="block" type="submit">
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </Button>
                        </Form.Group>
                    </div>
                </div> 
            </Form>           
            <hr />
            <div className="row">             
                <div className="col-7"></div>
                <div className="col-5">
                    <Form.Group controlId="deuda">
                        <Form.Label className="text-center">EFECTIVO TOTAL:</Form.Label>
                        <Form.Control type="text" value={ `S/ ${myConstClass.round_string(efectivo, 2)}` } className="text-end" readOnly />
                    </Form.Group>
                </div>
                <div className="col-12">                    
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={tableShow}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                    </div>
                </div>
            </div>
            <section 
                id="print"
                style={{
                    width: '220px',
                    "display" : 'none'
                }}
            >
                <div 
                    ref = {printRef} 
                    /* style={{
                        width: '100%', 
                        height: window.innerHeight
                    }} */
                    
                >
                    <div id="exportPDF" className="exportPDF">
                    {
                        dataPrint.map((row, key)=>(
                            <div key={key} className="ticket">
                                <div key="2"  className="cuerpo">
                                    <table className="table mb-0 text-start">
                                        <tbody>                                            
                                            <tr key="cab1" className="cab">
                                                <td key="0" colSpan="2">                                                
                                                    {myConstClass.VAR_GLOBAL.empresas[base].fullname} <br />
                                                    RECIBO DE CANCELACION <br />
                                                    {row.recibo_serie}-{row.recibo_numero}
                                                </td>
                                            </tr>
                                            <tr key="0" className="data">
                                                <td key="0"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    F.Emi.:
                                                </td>
                                                <td key="2">
                                                    { myConstClass.get_date(row.fecha_emision) }
                                                </td>
                                            </tr> 
                                            <tr key="1" className="data">
                                                <td 
                                                key="0"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    Recib de:
                                                </td>
                                                <td key="1">{row.cliente}</td>
                                            </tr>
                                            <tr key="5" className="data">
                                                <td                                    
                                                key="1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    Por Doc.:
                                                </td>
                                                <td                                   
                                                key="2">{row.documento_str}</td>
                                            </tr>
                                            <tr key="2" className="data">
                                                <td 
                                                key="b1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    Amortiz.:
                                                </td>
                                                <td
                                                key="b2"> S/ {myConstClass.round_string(row.monto, 2)}</td>
                                            </tr>
                                            <tr key="6" className="data">
                                                <td                                    
                                                key="1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    Saldo ac:
                                                </td>
                                                <td
                                                key="b2"> S/ {myConstClass.round_string(row.saldo_ref, 2)}</td>
                                            </tr>   
                                            <tr key="3" className="data">
                                                <td                                             
                                                key="1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    T. Pago:
                                                </td>
                                                <td                                   
                                                key="2">
                                                    {row.tipopago_string} - {row.caja_str}
                                                </td>
                                            </tr>
                                            <tr key="8" className="data">
                                                <td                                    
                                                key="1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    F. Oper.:
                                                </td>
                                                <td key="9">{myConstClass.get_date(row.fecha_operacion)}</td>
                                            </tr>
                                            <tr key="7" className="data">
                                                <td                                    
                                                key="1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    Cobrador:
                                                </td>
                                                <td key="9">{row.cobrado_por}</td>
                                            </tr>
                                            <tr key="footer" className="data">
                                                <td key="1" colSpan="2">
                                                    <center>
                                                        __________________________________
                                                    </center>
                                                </td>
                                            </tr>
                                            <tr key="footer2" className="data">
                                                <td                                    
                                                key="1"
                                                style={{
                                                    width : '18%'
                                                }}
                                                >
                                                    
                                                    NOMBRE <br />
                                                    DNI:<br />
                                                    FIRMA :
                                                </td>
                                                <td                                   
                                                key="2">
                                                </td>
                                            </tr>
                                            <tr key="footer3" className="data">
                                                <td key="1" colSpan="2">                                                
                                                    <center> 
                                                    Impreso : {new Date().toLocaleString() + ""}
                                                    </center>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))
                    }                        
                    </div>
                </div>
            </section>
            <Modal 
                show={modalEditar}
                onHide={handleCloseModalEditar}            
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >            
                <Modal.Body>
                    <h6 className="text-center text-uppercase"> Ver pago</h6>
                    <hr />
                    <Form onSubmit={confirmarPago}>
                        <div className="form-group">
                            <label >Cliente</label>
                            <label className="form-control"> {doc.cliente} </label>
                        </div>
                        <div className="row">
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Recibo de Cancelac.</label>
                                    <label className="form-control"> {doc.recibo_serie}-{doc.recibo_numero} </label>
                                </div>
                            </div>
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Amortizacion</label>
                                    <label  className="form-control text-end"> S/  {myConstClass.round_string(doc.monto, 2)} </label>
                                </div>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Registrado</label>
                                    <label className="form-control"> { doc.fecha_emision ?  myConstClass.get_date(doc.fecha_emision) : '-' } </label>
                                </div>
                            </div>
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Fecha Operac.</label>
                                    <label  className="form-control">{ doc.fecha_operacion ? myConstClass.get_date(doc.fecha_operacion) : '-' } </label>
                                </div>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Tipo Pago</label>
                                    <label className="form-control"> {doc.tipopago_string} </label>
                                </div>
                            </div>
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Destino</label>
                                    <label  className="form-control"> {doc.caja_str} </label>
                                </div>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">                                
                                <div className="form-group">
                                    <label >Cobrador</label>
                                    <label className="form-control"> {doc.cobrado_por} </label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="form-group">
                            <label className="form-control text-center"> Se realizo en una operacion por el monto de S/ {myConstClass.round_string(doc.monto_agrupado, 2)}  </label>
                        </div>
                        <br />
                        <ImagenModal />
                        
                        <hr />
                        {/* <Button variant="success" className="mt-2" size="lg" block="block" type="submit">
                            Aprobar
                        </Button> */}
                        <Button 
                            variant="outline-primary" 
                            className="mt-2 float-end" 
                            size="lg" 
                            block="block" 
                            type="button"
                            onClick={handleCloseModalEditar}
                        >
                            Regresar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
      </div>
    )
}
