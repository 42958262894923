import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DataTable from 'react-data-table-component';
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareUpRight } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function UpdatePagos() {
    const {http, catchError, jsonSaLoading, base} = AuthUser();
    const [documentos, setDocumentos] = useState([])
    const [tableShow, setTableShow] = useState([])

    
    
    
    const [tableDocumentos, setTableDocumentos] = useState([])
    const [tableTipoPago, setTableTipoPago] = useState([])
    const [tableCaja, setTableCaja] = useState([])

    const [paramDocumento, setParamDocumento] = useState(0);
    const [paramTipoPago, setParamTipoPago] = useState(0);
    const [paramCaja, setParamCaja] = useState(0);



    const [name, setName] = useState('');

    const [doc, setDoc ] = useState({})

    const [modalEditar, setModalEditar] = useState(false)
    
    const handleCloseModalEditar = () => {
        setModalEditar(false);
        /* resetFormPago(); */
    }

    const verPago = (cod) => {
        documentos.forEach(function(item, i){
            if(item.akey == cod ){
                /* console.log(item); */
                setDoc(item);
                return;
            }
        });
        handleShowModalEditar();
    }

    const handleShowModalEditar = () => {
        /* documentos.forEach(function(item, i){
            if(item.akey == id ){
                setDoc(item);
                return;
            }
        }); */
        setModalEditar(true);
    }

    useEffect(()=>{
        document.title = "Documentos - Caja";        
        var _data = JSON.parse(localStorage.getItem('dataDocumentos'));
        setTableDocumentos(_data);
        var _dataTP = JSON.parse(localStorage.getItem('dataTipoPago'));
        setTableTipoPago(_dataTP);
        var _dataC = JSON.parse(localStorage.getItem('dataCaja'));
        setTableCaja(_dataC);
        fetchAlquileres();


    },[])

    const fetchAlquileres = async () => {
        MySwal.fire(jsonSaLoading(MySwal));
        await http.get(`/${base}/documento_caja`).then(({data})=>{
            setTableShow(data);
            localStorage.setItem('dataDocCaja', JSON.stringify(data));
            MySwal.close();
        }).catch(({response:{data}})=>{
            catchError(data);
        });
    }

    const confirmarPago = async (caja) => {
        /* const isConfirm = await MySwal.fire({
          title: 'Desea continuar?',
          text: "Esta por APROBAR este pago",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelmButtonText: 'Regresar',
          confirmButtonText: 'Si, aprobar ahora!'
        }).then((result) => {
          return result.isConfirmed
        });
        if(!isConfirm){
          return;
        } */
        MySwal.fire(jsonSaLoading(MySwal, "Guardando..."));                
        const formData = new FormData()
        formData.append('documento', paramDocumento)
        formData.append('tipoPago', paramTipoPago)
        formData.append('caja', caja)
        await http.post(
            `/${base}/documento_caja`,
            formData
        ).then(({data})=>{
            MySwal.fire({
                text:data.message,
                icon:"success"
            })
            fetchAlquileres();          
        }).catch(({response})=>{                
            console.log("ERROR");
            if(response.status===422){
                MySwal.fire({
                    text:response.data.message,
                    icon:"error"
                    })
            }else{
                MySwal.fire({
                text:response.data.message,
                icon:"error"
                })
            }
        })
    }

    return (
      <div className="container">
            <h3>Documentos - Caja</h3>
            <hr />     
          <div className="row">
            <div className="col-12">
                <Form.Group controlId="selectDocumento">
                    <Form.Label>1 Elija Documento</Form.Label>
                    <Form.Control
                    as="select"
                    value={paramDocumento}
                    className="mb-2"
                    onChange={e => {
                        let _documento = e.target.value;
                        setParamDocumento(_documento)
                        setParamTipoPago(0)
                        setParamCaja(0)
                    }}
                    >
                        <option key={`all`} value={`0`}>{`Elegir Documento`}</option>
                        {tableDocumentos.map((item, idx) => {
                            return (
                                <option 
                                    key={idx} 
                                    value={item.id}
                                > 
                                    {item.descripcion}
                                </option>
                            );
                        } )}
                    </Form.Control>
                </Form.Group>
            </div>
            <div className="col-12">
                <hr />
                <Form.Group controlId="selectTipoPago">
                    <Form.Label>2 Elija Tipo Pago</Form.Label>
                </Form.Group>
            </div>
            {
                tableTipoPago.length > 0 && (
                    tableTipoPago.map((row, key)=>(                        
                        <div className="col-12" key={key}>
                            <div className="d-grid">
                                <Button
                                    variant={(tableShow.filter((i) => { return i.documento == paramDocumento && i.tipopago == row.id })).length > 0 ? ( paramTipoPago == row.id ? `primary` : `outline-primary` ) : ( paramTipoPago == row.id ? `primary` : `light` )}
                                    className="btn-block text-start mt-2 " 
                                    size="sm" 
                                    block="block" 
                                    disabled={ paramDocumento == 0 ? true : false }
                                    type="button"
                                    onClick={(e)=>{
                                        setParamTipoPago(row.id)
                                    }}
                                >
                                     {row.descripcion}
                                </Button>

                            </div>
                        </div>
                    ))
                )
            }
            <div className="col-12">
                <hr />
                <Form.Group controlId="selectTipoPago">
                    <Form.Label>3 Elija Cajas</Form.Label>
                </Form.Group>
            </div>            
            {
                tableCaja.length > 0 && (
                    tableCaja.map((row, key)=>(                        
                        <div className="col-12" key={'a'+ key}>
                            <div className="d-grid">
                                <Button 
                                    variant={(tableShow.filter((i) => { return (i.documento == paramDocumento && i.tipopago == paramTipoPago && i.caja == row.id) })).length > 0 ? `success` : `light`}
                                    className="btn-block text-start mt-2 " 
                                    size="sm" 
                                    block="block" 
                                    type="button"
                                    disabled={ paramDocumento == 0 || paramTipoPago == 0 ? true : false }
                                    onClick={(e)=>{
                                        confirmarPago(row.id)
                                    }}
                                >
                                    {row.descripcion}
                                </Button>

                            </div>
                        </div>
                    ))
                )
            }
          </div>
          <br />
          <br />
      </div>
    )
}
