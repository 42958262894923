import { useState } from "react"
import AuthUser from './AuthUser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { faBullseye } from "@fortawesome/free-solid-svg-icons";


const MySwal = withReactContent(Swal)

export default function Login() {
    const {http,base,setToken,jsonSaLoading, cargarDataGoToMain} = AuthUser();
    const [email,setEmail] = useState();
    const [password,setPassword] = useState();

    const submitForm = () =>{
        // api call        
        MySwal.fire(jsonSaLoading(MySwal, "Iniciando sesion "));
        http.post('/login',{username:email,password:password}).then((res)=>{
            /* console.log(res.data); */
            MySwal.close();
            setToken(res.data.user,res.data.access_token);
            /* console.log(base); */
            /* cargarDataGoToMain(http,base); */
        }).catch(({response:{data}})=>{
            MySwal.fire({
                title: 'Error al iniciar sesion',
                text: "Verifique que los datos sean correctos",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Volver a intentar'
             })
        })
    }

    return(
        <div className="container">
            <div className="row justify-content-center pt-5">
                <div className="col-sm-6">
                    <div className="card p-4">
                        <h1 className="text-center mb-3">Iniciar Sesión </h1>
                        <div className="form-group">
                            <label>Usuario:</label>
                            <input type="text" className="form-control" placeholder=""
                                onChange={e=>setEmail(e.target.value)}
                            id="username" />
                        </div>
                        <div className="form-group mt-3">
                            <label>Clave:</label>
                            <input type="password" className="form-control" placeholder=""
                                onChange={e => setPassword(e.target.value)}
                            id="pwd" />
                        </div>
                        <button type="button" onClick={submitForm} className="btn btn-primary mt-4">Iniciar sesión</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
