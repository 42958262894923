import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as myConstClass from '../constantes.js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwalInto = withReactContent(Swal)

export default function AuthUser(){
    const navigate = useNavigate();

    const getToken = () =>{
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const getIdentidad = () =>{
        const identidadString = sessionStorage.getItem('sisvet_identidad');
        const identidad_detail = JSON.parse(identidadString);
        return identidad_detail;
    }

    const getBase = () =>{
        const baseString = sessionStorage.getItem('sisvet_base');
        return baseString;
    }

    const catchError = (error) =>{
        let mensaje = "Intente nuevamente o cierre e inicie sesion.";
        let accion = "";
        if(error.message){
            mensaje = error.message;
        }else{
            if(error.status){
                mensaje = error.status;
                accion = error.action;
            }else{
                mensaje = error;
                /* console.log('ERROR :', error); */
            }
        }            
        Swal.fire({
            text:mensaje,
            icon:"error"
        })
        if(accion === "redirect_login"){
            sessionStorage.clear();
            localStorage.clear();
            navigate('/login');
        }
    }

    const jsonSaLoading = (MySwal , Mensaje="Cargando información") =>{
        return {
            text: Mensaje,
            didOpen: () => {
                MySwal.showLoading()
            },
            showConfirmButton: false,
            allowOutsideClick: false
        }

    }

    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());
    const [identidad,setIdentidad] = useState(getIdentidad());
    const [base,setBase] = useState(getBase());

    const saveToken = (user,token) =>{
        let basedata = "brivet";
        sessionStorage.setItem('token',JSON.stringify(token));
        sessionStorage.setItem('user',JSON.stringify(user));
        let _identidad = {
            'cobrador' : user.brivetCod,
            'cobrador_name' : user.brivetName
        }
        sessionStorage.setItem('sisvet_identidad', JSON.stringify(_identidad)); 
        sessionStorage.setItem('sisvet_base', basedata);

        setToken(token);
        setUser(user);
        setIdentidad(_identidad);
        setBase(basedata);

        cargarDataGoToMain(basedata, token);


        /* myConstClass.VAR_GLOBAL.baseprincipal = 'brivet';
        myConstClass.VAR_GLOBAL.cobrador = user.brivetCod;
        myConstClass.VAR_GLOBAL.cobrador_name = user.brivetName; */        
    }

    const cargarDataGoToMain = (_base = "", _token = "") => {
        let tok = _token == "" ? token : _token;
        let bas = _base == "" ? base : _base;
        MySwalInto.fire({
            text: "Cargando datos iniciales",
            didOpen: () => {
                MySwalInto.showLoading()
            },
            showConfirmButton: false,
            allowOutsideClick: false
        });
        axios.create({
            baseURL:`${myConstClass.URL_APP}/api/`,
            headers:{
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type',
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${tok}`
            }
        }).get(`/${bas}/config/getInit`).then(({data})=>{
            MySwalInto.close();
            localStorage.setItem('dataCobradores', JSON.stringify(data.cobradores));
            localStorage.setItem('dataDocumentos', JSON.stringify(data.documento));
            localStorage.setItem('dataTipoPago', JSON.stringify(data.tipopago));
            localStorage.setItem('dataCaja', JSON.stringify(data.caja));
            localStorage.setItem('dataDocCaja', JSON.stringify(data.doccaja));
            navigate('/');
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    const saveIdentidad = (cob, cobName) => {
        let _identidad = {
            'cobrador' : cob,
            'cobrador_name' : cobName
        }
        sessionStorage.setItem('sisvet_identidad', JSON.stringify(_identidad)); 
        setIdentidad(_identidad);
    }

    const saveBase = (baseprincipal) => {
        sessionStorage.setItem('sisvet_base', baseprincipal);
        setBase(baseprincipal);
    }

    const logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
    }

    const http = axios.create({
        baseURL:`${myConstClass.URL_APP}/api/`,
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type',
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`
        }
    });
    return {
        setToken:saveToken,
        setBase:saveBase,
        setIdentidad:saveIdentidad,
        token,
        user,
        identidad,
        base,
        getToken,getBase,getIdentidad,
        http,
        logout,
        catchError,
        jsonSaLoading,
        cargarDataGoToMain
    }
}
