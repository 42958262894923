
import "bootstrap/dist/css/bootstrap.min.css";
import AuthUser from './components/AuthUser';
import Auth from './components/Auth';
import Intro from './components/Intro';

function App() {
  const {getToken} = AuthUser();
  if(!getToken()){
    /* console.log('INTRO'); */
    return <Intro />
  }
  /* console.log("Se va a auth"); */
  return (
      <Auth />
  );
}

export default App;
