import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import * as myConstClass from '../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import AuthUser from './AuthUser'

export default function Home() {    
    const {http, catchError, base, user} = AuthUser();

    const [pendientesVerificar, setPendientesVerificar] = useState(0)
    useEffect(()=>{        
        document.title = "Inicio"
        fetchAlquileres();
    },[])

    const fetchAlquileres = async () => {
        await http.get(`/${base}/getPendientes`).then(({data})=>{
            let count_ = data.length;
            setPendientesVerificar(count_);
        }).catch(({response:{data}})=>{
            catchError(data);
        });
    }

    const PagosPorRevisar = (props)=> {
        if (user.rol === "ADMINISTRADOR" && pendientesVerificar > 0) {
            return (            
                <div className="col-12">
                    <Link to={`/pagos/pendientes`} onClick={props.onHide} className="" style={{textDecoration : 'none'}} >                        
                        <div className="card text-bg-warning">
                            <div className="card-body">
                                <h6 className="card-title"><FontAwesomeIcon icon={faCircleExclamation} /> {pendientesVerificar} Cobros por revisar !</h6>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        }else{
            return (
                <div className="col-12"></div>
            );
        }
    }


    return (
      <div className="container">
          <div className="row">
            <PagosPorRevisar props/>
          </div>
      </div>
    )
}
