import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import * as myConstClass from '../../constantes.js';
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function EditarTipoPago() {
  const {http, catchError, jsonSaLoading, base} = AuthUser();
  const navigate = useNavigate();

  const { id } = useParams()

  const [razonsocial, setRazonSocial] = useState("")
  const [tipoCliente, setTipoCliente] = useState("TODOS")
  const [documento, setDocumento] = useState("")
  const [numeroDocumento, setNumeroDocumento] = useState("")
  const [validationError,setValidationError] = useState({})

  useEffect(()=>{
    fetchProduct()
  },[])

  const fetchProduct = async () => {    
    MySwal.fire(jsonSaLoading(MySwal));
    await http.get(`/${base}/mantenedor/clientes/${id}`).then(({data})=>{      
      MySwal.close();
      const { descripcion, Doc, Nro, tipo_cliente } = data.cliente ;
      let _tipo_cliente = tipo_cliente != null ? tipo_cliente : 'TODOS' ;
      setRazonSocial(descripcion)
      setTipoCliente(_tipo_cliente)
      setDocumento(Doc)
      setNumeroDocumento(Nro)
      /* console.log(_validacion_imagen, _notificacion_alerta); */
    }).catch(({response:{data}})=>{
      catchError(data);
    })
  }

  const updateProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('_method', 'PATCH');
    formData.append('tipoCliente', tipoCliente)

    MySwal.fire(jsonSaLoading(MySwal, "Modificando registro"));
    await http.post(`/${base}/mantenedor/clientes/${id}`, formData).then(({data})=>{
      Swal.fire({
        icon:"success",
        text:data.message,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
      /* navigate("/tipopago") */
    }).catch(({response})=>{
        if(response.status===422){
            setValidationError(response.data.errors)
        }else{        
            catchError(response);
        }
    })
  }


  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Actualizar Cliente</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>   
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={updateProduct}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="Description">
                            <Form.Label>Cliente</Form.Label>
                            <Form.Control type="text" value={razonsocial} readOnly/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="doc">
                            <Form.Label>Documento</Form.Label>
                            <Form.Control type="text" value={documento} readOnly/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="num">
                            <Form.Label>Numero</Form.Label>
                            <Form.Control type="text" value={numeroDocumento} readOnly/>
                        </Form.Group>
                      </Col>
                  </Row>                  
                  <hr />
                  <Form.Group controlId="selectTipo">
                          <Form.Label>Tipo Cliente</Form.Label>
                          <Form.Control
                          as="select"
                          value={tipoCliente}
                          onChange={e => {
                              let _documento = e.target.value;
                              setTipoCliente(_documento)
                          }}
                          >
                              <option key={`TODOS`} value={`TODOS`}>TODOS</option>
                              <option key={`MAYORES`} value={`MAYORES`}>MAYORES</option>
                              <option key={`MENORES`} value={`MENORES`}>MENORES</option>
                          </Form.Control>
                    </Form.Group>                
                  <Button variant="primary" className="mt-2 btn-sm" size="lg" block="block" type="submit">
                    Actualizar
                  </Button>
                  <Link className='btn btn-outline-primary btn-sm mt-2 float-end' to={"/clientes/"}>
                      Ir a la lista
                  </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
