import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function ListCorrelativo() {
    const {http, catchError, jsonSaLoading, base} = AuthUser();
    const [datosTabla, setDatosTabla] = useState([])

    useEffect(()=>{
        fetchProducts() 
    },[])

    const fetchProducts = async () => {
        MySwal.fire(jsonSaLoading(MySwal));
        await http.get(`/${base}/mantenedor/correlativo`).then(({data})=>{
            MySwal.close();
            setDatosTabla(data)
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    const deleteProduct = async (id) => {
        const isConfirm = await Swal.fire({
            title: 'Desea continuar?',
            text: "Esta accion no puede ser revertida",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar.'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }

          await axios.delete(`${myConstClass.URL_APP}/api/${base}/mantenedor/correlativo/${id}`).then(({data})=>{
            Swal.fire({
                icon:"success",
                text:data.message
            })
            fetchProducts()
          }).catch(({response:{data}})=>{
            Swal.fire({
                text:data.message,
                icon:"error"
            })
          })
    }

    return (
      <div className="container">        
            <h4 className="text-center">Correlativo cobradores</h4>        
            <hr />
          <div className="row">
            <div className="col-12">
                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0 text-center">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>PREFIJO</th>
                                    <th>NUMERO</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datosTabla.length > 0 && (
                                        datosTabla.map((row, key)=>(
                                            <tr key={key}>
                                                <td className="text-start">{row.descripcion}</td>
                                                <td>{row.recibo_serie}</td>
                                                <td>{row.recibo_numero}</td>
                                                <td>
                                                    <Link to={`/correlativo_trabajador/edit/${row.id}`} className='btn btn-success btn-sm me-2' >
                                                        <FontAwesomeIcon icon={faPenToSquare}/>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
      </div>
    )
}
