import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Pagination from "react-js-pagination";
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function ListClientes() {
    const {http, catchError, jsonSaLoading, base} = AuthUser();
    const [datosTabla, setDatosTabla] = useState({
        data : [],

    })
    const [page, setPage] = useState(1)

    useEffect(()=>{
        fetchProducts() 
        /* console.log(datosTabla); */
    },[])

    const fetchProducts = async (varPage = 1) => {
        MySwal.fire(jsonSaLoading(MySwal, "Cargando datos, página " + varPage));
        await http.get(`/${base}/mantenedor/clientes?page=${varPage}`).then(({data})=>{            
            MySwal.close();
            /* console.log(data); */
            setDatosTabla(data)
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }

    const deleteProduct = async (id) => {
        const isConfirm = await Swal.fire({
            title: 'Desea continuar?',
            text: "Esta accion no puede ser revertida",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar.'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }

          await axios.delete(`${myConstClass.URL_APP}/api/${base}/products/${id}`).then(({data})=>{
            Swal.fire({
                icon:"success",
                text:data.message
            })
            fetchProducts()
          }).catch(({response:{data}})=>{
            Swal.fire({
                text:data.message,
                icon:"error"
            })
          })
    }


    return (
      <div className="container">        
            <h4 className="text-center">Clientes</h4>        
            <hr />
          <div className="row">
            {/* <div className='col-12'>
                <Link className='btn btn-primary mb-2 float-end' to={"/tipopago/create"}>
                    Nuevo
                </Link>
            </div> */}
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-bordered mb-0 text-center">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                datosTabla.data.length > 0 && (
                                    datosTabla.data.map((row, key)=>(
                                        <tr key={key}>
                                            <td className="text-start">{row.descripcion}</td>
                                            <td className="text-start">{row.tipo_cliente ? row.tipo_cliente : '??' }</td>
                                            <td>
                                                <Link to={`/clientes/edit/${row.id}`} className='btn btn-success btn-sm me-2' >
                                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                                </Link>
                                                {/* <Button variant="danger" className="btn-sm" onClick={()=>deleteProduct(row.id)}>
                                                    <FontAwesomeIcon icon={faTrashCan}/>
                                                </Button> */}
                                            </td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                    <br />
                    <center className="text-center">
                        <Pagination
                            activePage={datosTabla.current_page}
                            itemsCountPerPage={datosTabla.per_page}
                            totalItemsCount={datosTabla.total ? datosTabla.total : 0}
                            onChange={(pageNumber)=> fetchProducts(pageNumber)}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </center>
                </div>
            </div>
          </div>
      </div>
    )
}
