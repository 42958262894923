import React, { useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom'
import * as myConstClass from '../../constantes.js';
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function CrearUsuario() {
  const {http, catchError, jsonSaLoading, base} = AuthUser();
  const navigate = useNavigate();

  const [description, setDescription] = useState("")
  const [imagenValidar, setImagenValidar] = useState("0")
  const [notificar, setNotificar] = useState("0")
  const [validationError,setValidationError] = useState({})


  const createData = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('descripcion', description)
    formData.append('imagenval', imagenValidar)
    formData.append('notificar', notificar)

    MySwal.fire(jsonSaLoading(MySwal, "Guardando registro"));
    await http.post(`/${base}/mantenedor/tipopago`, formData).then(({data})=>{
      MySwal.fire({
        icon:"success",
        text:data.message,        
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Regresar a la lista'
      }).then((result) => { 
        if(result.isConfirmed == true){     
          navigate("/tipopago")
        }
      })
    }).catch(({response})=>{
      if(response.status===422){
        setValidationError(response.data.errors)
      }else{        
        catchError(response);
      }
    })
  }

  const handleImagenValidarChange = e => {
    e.persist();
    setImagenValidar(e.target.value);
  };

  const handleNotificarChange = e => {
    e.persist();
    setNotificar(e.target.value);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Nuevo Tipo de Pago</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>   
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={createData}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="Description">
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control type="text" value={description} onChange={(event)=>{
                              setDescription(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <hr />
                  <Form.Group controlId="radio_imagen">                      
                    <Form.Label>Validar por imagen</Form.Label>                
                      <Row className="my-3">                        
                        <Col>
                          <Form.Check
                              value="0"
                              type="radio"
                              aria-label="radio 1"
                              label="NO"
                              onChange={handleImagenValidarChange}
                              checked={imagenValidar === "0"}
                            />
                        </Col>
                        <Col>
                          <Form.Check
                              value="1"
                              type="radio"
                              aria-label="radio 2"
                              label="SI"
                              onChange={handleImagenValidarChange}
                              checked={imagenValidar === "1"}
                            />
                        </Col>                     
                        <Col></Col>
                      </Row>
                  </Form.Group>
                  <hr />
                  <Form.Group controlId="radio_notificar">                      
                    <Form.Label>Notificar</Form.Label>                
                      <Row className="my-3">                        
                        <Col>
                          <Form.Check
                              value="0"
                              type="radio"
                              aria-label="radio 3"
                              label="NO"
                              onChange={handleNotificarChange}
                              checked={notificar === "0"}
                            />
                        </Col>
                        <Col>
                          <Form.Check
                              value="1"
                              type="radio"
                              aria-label="radio 4"
                              label="SI"
                              onChange={handleNotificarChange}
                              checked={notificar === "1"}
                            />
                        </Col>                     
                        <Col></Col>
                      </Row>
                  </Form.Group>
                  <Button variant="primary" className="btn-sm mt-2" size="lg" block="block" type="submit">
                    Guardar
                  </Button>
                  <Link className='btn btn-outline-primary btn-sm mt-2 float-end' to={"/tipopago/"}>
                      Ver todos
                  </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
