import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import * as myConstClass from '../../constantes.js';
import "../../App.css";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import InputGroup from 'react-bootstrap/InputGroup';
import {useReactToPrint} from 'react-to-print'
import imgThumb from '../../thumb.svg';
import Resizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthUser from '../AuthUser';

export default function Home() {    
    const {http, catchError, base, user} = AuthUser();

    const [pendientesVerificar, setPendientesVerificar] = useState(0)

    
    const [imagenPago, setImagenPago] = useState("");
    const [imageView, setImageView] = useState(imgThumb);

    const [imagenPago2, setImagenPago2] = useState("");

    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagenPago(event.target.files[0]);
            Resizer.imageFileResizer(
                event.target.files[0],
                500,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                    /* console.log(uri); */
                    setImageView(uri)
                },
                "base64",
                200,
                200
              );
            /* setImageView(URL.createObjectURL(event.target.files[0])); */
        }
	};


    useEffect(()=>{        
        document.title = "Inicio"
    },[])


    return (
      <div className="container">
          <div className="row">
            <div className="col-6">
                <Form.Group controlId="Image" className="mb-3">
                    <Form.Label>Image</Form.Label>
                    <Form.Control 
                        type="file"                             
                        accept="image/*"
                        onChange={changeHandlerImage} 
                    />
                </Form.Group>
            </div>
            <div className="col-6">
                <Form.Group controlId="Preview">
                    <Form.Label><strong>Preview</strong></Form.Label>
                </Form.Group>
                <img className="w-100" src={imageView} alt="" />
            </div>            
            <div className="col-12">
                <Form.Group controlId="Image" className="mb-3">
                    <Form.Label>Image 2</Form.Label>
                    <Form.Control 
                        type="file"                             
                        accept="image/*"
                    />
                </Form.Group>
            </div>
          </div>
      </div>
    )
}

